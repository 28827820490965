import './App.css';
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import LoaderImage from './loader_image.svg';
import logoLego from './logo_lego.png';
import logoSony from './logo_sony.png';
import logoUbisoft from './logo_ubisoft.png';
import { useTranslation } from 'react-i18next';

function Form() {
    let navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [popup, setPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [states, setState] = useState({
        uuid: '',
        lastname: '',
        firstname: '',
        email: '',
        tel: '',
        adr: '',
        cp: '',
        ville: '',
        shop: '',
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const uuid = queryParams.get('uuid');
        const email = queryParams.get('email');
        let getState = { ...states }
        getState.uuid = uuid
        getState.email = email
        setState(getState)
    }, [])

    const submit = e => {
        setLoading(true);
        var formData = new FormData();
        formData.append('uuid', states.uuid);
        formData.append('lastname', states.lastname);
        formData.append('firstname', states.firstname);
        formData.append('username', states.email);
        formData.append('phone', states.tel);
        formData.append('address', states.adr);
        formData.append('zipcode', states.cp);
        formData.append('city', states.ville);
        formData.append('consent', 1);

        if (process.env.REACT_APP_SHOP === "true")
            formData.append('customData', states.shop);

        e.preventDefault();

        fetch(`${process.env.REACT_APP_API}/public/asset/registerdata/add`, {
            method: 'POST',
            body: formData,
        }).then(res => res.json())
            .then((result) => {
                if (result.success == false) {
                    if (result.code === 'not_registered') {
                        setPopupMessage("Vous n'avez pas joué au jeu-concours.");
                        setPopup(true);
                        setLoading(false);
                        return;
                    }

                    if (result.code === 'already_existed') {
                        setPopupMessage("Vous avez déjà enregistré vos coordonnées.");
                        setPopup(true);
                        setLoading(false);
                        return;
                    }

                    setPopupMessage("Veuillez vérifier vos informations.");
                    setPopup(true);
                    setLoading(false);
                } else {
                    setLoading(false);
                    navigate('/validation');
                }
            })
    }

    const hidePopup = e => {
        setPopup(false);
    }

    const stateCallBack = (stateName, value) => {
        let getState = { ...states }
        getState[stateName] = value?.target?.value ?? value
        setState(getState)
    }

    const logo = useMemo(() => {
        switch (process.env.REACT_APP_CLIENT_IDENTIFIER) {
            case 'lego':
                return logoLego;
            case 'sony':
                return logoSony;
            case 'ubisoft':
                return logoUbisoft;
            default:
                return null;
        }
    }, []);
    
    return (
        <div className="App">
            <div className="Bloc_app">
                {logo ? <img src={logo} className="App-logo" alt="logo" /> : null}
                <strong className="felicitations">{t('text1')}</strong>
                <p className="text">{t('text2', { client: process.env.REACT_APP_CLIENT_NAME })}</p>
                <p className="text">{t('text3')} { process.env.REACT_APP_SHOP === "true" && t('text6') }</p>

                <p className="text red">
                    <strong className="red">{t('text4', { date: process.env.REACT_APP_LIMIT_DATE })}</strong> 
                    <span> {t('text5')}</span>
                </p>
                <form className="Form" onSubmit={submit}>
                    <div className="Form_label">
                        <label htmlFor="lastname" className="label">
                            {t('name')}* :
                        </label>
                        <input type="text" name="lastname" value={states.lastname} required onChange={e => stateCallBack('lastname', e)} />
                    </div>
                    <div className="Form_label">
                        <label htmlFor="firstname" className="label">
                            {t('firstname')}* :
                        </label>
                        <input type="text" name="firstname" value={states.firstname} required onChange={e => stateCallBack('firstname', e)} />
                    </div>
                    <div className="Form_label">
                        <label htmlFor="email" className="label">
                            {t('email')}* :
                        </label>
                        <input type="email" name="email" value={states.email} disabled readOnly required />
                    </div>
                    <div className="Form_label">
                        <label htmlFor="tel" className="label">
                            {t('phone')}* :
                        </label>
                        <input type="tel" name="tel" value={states.tel} required onChange={e => stateCallBack('tel', e)} />
                    </div>
                    <div className="Form_label">
                        <label htmlFor="adr" className="label">
                            {t('address')}* :
                        </label>
                        <input type="text" name="adr" value={states.adr} required onChange={e => stateCallBack('adr', e)} />
                    </div>
                    <div className="Form_label">
                        <label htmlFor="cp" className="label">
                            {t('postalcode')}* :
                        </label>
                        <input type="text" name="cp" value={states.cp} required onChange={e => stateCallBack('cp', e)} />
                    </div>
                    <div className="Form_label">
                        <label htmlFor="city" className="label">
                            {t('city')}* :
                        </label>
                        <input type="text" name="city" value={states.ville} required onChange={e => stateCallBack('ville', e)} />
                    </div>

                    {
                        process.env.REACT_APP_SHOP === "true" && process.env.REACT_APP_LANG === "fr" && process.env.REACT_APP_SHOP_A === "true" && (
                            <div className="Form_label">
                                <label htmlFor="shop" className="label">
                                    {t('shop')}* :
                                </label>
                                <select required name="shop" onChange={e => stateCallBack('shop', e)}>
                                    <option value="bordeaux">Bordeaux</option>
                                    <option value="clermontferrand">Clermont-Ferrand</option>
                                    <option value="disneylandparis">Disneyland Paris</option>
                                    <option value="lille">Lille</option>
                                    <option value="marseille">Marseille</option>
                                    <option value="nice">Nice</option>
                                    <option value="parisforumdeshalles">Paris, Forum Des Halles</option>
                                    <option value="parissoouest">Paris So Ouest</option>
                                    <option value="lyonlapartdieu">Lyon La Part Dieu</option>
                                </select>
                            </div>
                        )
                    }

                    {
                        process.env.REACT_APP_SHOP === "true" && process.env.REACT_APP_LANG === "fr" && process.env.REACT_APP_SHOP_B === "true" && (
                            <div className="Form_label">
                                <label htmlFor="shop" className="label">
                                    {t('shop')}* :
                                </label>
                                <select required name="shop" onChange={e => stateCallBack('shop', e)}>
                                    <option value="legodijon">LEGO Dijon</option>
                                    <option value="legotoulouseblagnac">LEGO Toulouse Blagnac</option>
                                    <option value="legorosny2">LEGO Rosny 2</option>
                                    <option value="legocréteilsoleil">LEGO Créteil Soleil</option>
                                    <option value="legostrasbourg">LEGO Strasbourg</option>
                                    <option value="legolehavre">LEGO Le Havre</option>
                                    <option value="legogrenoble">LEGO Grenoble</option>
                                    <option value="legonantes">LEGO Nantes</option>
                                    <option value="legocergy">LEGO Cergy</option>
                                    <option value="legorennes">LEGO Rennes</option>
                                </select>
                            </div>
                        )
                    }

                    {
                        process.env.REACT_APP_SHOP === "true" && process.env.REACT_APP_LANG === "es" && process.env.REACT_APP_SHOP_A === "true" && (
                            <div className="Form_label">
                                <label htmlFor="shop" className="label">
                                    {t('shop')}* :
                                </label>
                                <select required name="shop" onChange={e => stateCallBack('shop', e)}>
                                    <option value="madridlavaguada">Madrid La Vaguada</option>
                                    <option value="madridparquesur">Madrid Parquesur</option>
                                    <option value="barcelonalilladiagonal">Barcelona L'illa Diagonal</option>
                                    <option value="zaragozapuertovenecia">Zaragoza Puerto Venecia</option>
                                    <option value="barcelonamaquibista">Barcelona Maquinista</option>
                                    <option value="sevillalagoh">Sevilla Lagoh</option>
                                </select>
                            </div>
                        )
                    }
                   
                    <div className="Form_data">
                        <input type="checkbox" name="data" id="data" className="data" required />
                        <label for="data">
                            {t('data', { client: process.env.REACT_APP_CLIENT_NAME })}&nbsp;**
                        </label>
                    </div>
                    <input type="submit" value={t('submit')} class="button" />
                    <div className="Form_indication">
                        <p className="indication">* {t('required')}<br></br>
                            ** {t('keep', { client: process.env.REACT_APP_CLIENT_NAME })}</p>
                    </div>
                </form>
            </div>

            {
                loading ?
                    <div className='loader-container'>
                        <img className='loader' src={LoaderImage} />
                    </div>
                    : null
            }
            {
                popup ?
                    <div className='popup'>
                        <div className='popup_error'>
                            <p className="text error">{popupMessage}</p>
                            <button onClick={hidePopup} className='button' target="_blank">Retour</button>
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default Form;
