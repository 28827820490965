import React, { useMemo } from 'react';
import logoLego from './logo_lego.png';
import logoSony from './logo_sony.png';
import logoUbisoft from './logo_ubisoft.png';

function Validation() {
  const logo = useMemo(() => {
    switch (process.env.REACT_APP_CLIENT_IDENTIFIER) {
      case 'lego':
        return logoLego;
      case 'sony':
        return logoSony;
      case 'ubisoft':
        return logoUbisoft;
      default:
        return null;
    }
  }, []);

  return (
    <div className="Bloc_app">
      {logo ? <img src={logo} className="App-logo" alt="logo" /> : null}
      <p className="text validation">Nous avons bien enregistré vos coordonnées ! Votre cadeau arrivera dans un délai de trois mois après la réception de ces derniers.</p>
      <p className="text">En attendant n’hésitez pas à faire un tour sur notre boutique en ligne.</p>
      <button onClick={() => {
        window.open(process.env.REACT_APP_SHOP_URL, '_blank');
      }} className='button'>Visitez la boutique en ligne</button>
      {/* <div className="Form_indication">
          <p className="mentions">Mentions légales</p>
        </div> */}
    </div>
  );
}

export default Validation;








